<template>
  <div>
    <!-- <h3>当前我的等级: {{$store.getters.getUserInfo.group_text}}</h3> -->
        <get-price ></get-price>
  </div>
</template>

<script>
import getPrice from 'components/price'
export default {
  components: {
    getPrice
  }
}
</script>
<style lang="scss" scoped>

</style>
